.category-item img {
    max-width: 100%;
    border-radius: 10px;
    max-height: 240px;
}

.mobile-product {
    width: 48px;
    height: 48px;
    background-color: #f6f6f6;
    border-radius: 12px;
    overflow: hidden;
}

.sub-p-mobile {
    margin-top: 4px;
    color: black;
    font-weight: bold;
}

.category-item p {
    text-align: center;
    margin: 10px 0px;
    font-size: 16px;
    color: #000;
}

.category-item a {
    text-decoration: none;
}

.categories-container {
    margin-bottom: 30px;
}

.category-item {
    text-align: center;
}

.subcat-item-container img {
    max-width: 100%;
    border-radius: 5px;
}

.subcat-item-container {
    padding: 10px 20px;
    border: 1px solid #ddd;
}

.subcat-item-container-mobile {
    margin: 12px 0px 0px 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.subcat-item-container-mobile a {
    text-decoration: none;
}

.subcategories-list {
    border: 1px solid #ddd;
    padding: 10px 0px;
}

.subcategories-list-mobile {
    box-shadow: 1px 0px 3px -3px rgba(0, 0, 0, 0.75);
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    min-width: 84px;
    max-width: 84px;
    /* overflow-y: scroll; */
    box-sizing: border-box;
}

.subcat-item-container a {
    color: #000;
    text-decoration: none;
}

.sub-p-container {
    text-align: center;
}

p.sub-p {
    margin: 0px;
    vertical-align: middle;
    text-align: center;
}

.subcat-item-container:hover {
    background: #eafdeb;
}

.posthead,
.postheads {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.postheads {
    margin-bottom: 20px;
}

.posthead {
    flex-direction: column;
}

.posthead p,
.postheads p {
    margin-bottom: 0px;
    line-height: 27px;
}

.search-container .profile-title .ellipse_container,
.search-container .actionsdp-list .ellipse_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
}

.search-container .profile-title .ellipse_container .textellipse,
.search-container .actionsdp-list .ellipse_container .textellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    display: inline-block;
}

.profile-actions .actionsdp-list {
    align-items: center;
    color: #000;
    display: flex;
    justify-content: space-between;
}

.profile-pic-div {
    flex: 1;
    /* display: flex; */
    /* align-items: center; */
    justify-content: center;
    /* margin-bottom: 13px; */
}

.profile-pic-divs {
    flex: 1;
}

.profile-name {
    color: #000;
    text-decoration: none;
    font-size: 17px;
    line-height: 27px;
    font-weight: 630;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-container .hcc_id-post {
    line-height: 0;
    max-width: 117px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.post-age {
    display: flex;
    margin-left: 5px;
    color: #555555;
}

.hcc_id-post a {
    text-decoration: none;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #212529;
}

.follow-dec {
    color: #6A0DAD;
}

.profile-pic-home {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 18px;
}

.profile-title {
    flex: 12;
    /* text-align: center; */
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #000;
}

.search-post-count {
    line-height: 17.6px;
    text-align: left;
    color: #555555;
}

.search-post-count span {
    font-weight: 500;
}

.profile-titles {
    flex: 12;
    /* margin-left: 8px; */
}

/* p.profile-actions {
    flex: 1;
} */

.post-img {
    max-width: 100%;
    max-height: calc(100vh - 120px);
    /* width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: top; */
}

.post-symbol-icons {
    font-size: 30px;
    width: 32px;
    height: 32px;
    /* margin-right: 10px; */
}

.post-footer {
    margin: 19px 0px 11px;
    display: flex;
}

.symbols {
    display: flex;
    flex: 1;
    gap: 15px;
}

.post-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    font-family: "poppins";
    line-height: 20px;
    color: #0E1027;
}

.icon-rotate {
    transform: rotate(-45deg);
}

.more-icon {
    font-size: 27px;
    position: relative;
    /* z-index: -1; */
}

.more_iconmobileres {
    z-index: -1 !important;
}

.posting-container {
    padding: 10px 0px;
    margin-top: 14px;
}

.post-item {
    margin-bottom: 26px;
}

.post-image {
    text-align: center;
}

.viewphoto-popup .modal-body {
    padding: 0px;
}

.shared-post .post-modal-view,
.modal-content .post-modal-view {
    display: flex;
    min-height: 546px;
    overflow: hidden;
    max-height: 546px;
}

img.post-modal-img {
    max-width: 100%;
    max-height: 580px;
}

.pm-img-container {
    flex: 1;
    justify-content: center;
}

.imgholder {
    height: 100%;
    display: grid;
    place-items: center;
    background: #000000;
}

.pm-content-container {
    flex: 1;
}

.homescreen .post-inner-container {
    width: 100%;
    /* margin-left: auto;
    margin-right: auto; */
}

.search-container .profile-pic-div {
    background-color: #000;
    text-align: center;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 250px;
}

.search-container .profile-pic-div .profile-pic {
    max-width: 100%;
    width: 100%;
    height: 100%;
    /* width: auto;
    height: auto; */
}

/* .search-container .profile-title {
    margin-left: 20px;
} */

.search-container .profile-title p {
    font-size: 16px;
    /* margin-bottom: 10px; */
}

.search-container .profile-title p a {
    font-size: 16px;
    font-weight: 600;
}

img.post-dp {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.pdpop-header {
    padding: 10px;
    display: flex;
}

.pdpop-ls {
    flex: 1;
}

.pdpop-ls-container {
    display: flex;
}

.pdpop-titles {
    flex: 9;
}

.pdpop-titles p {
    margin-bottom: 0px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
}

.pdpop-titles p:last-child {
    font-size: 12px;
    font-weight: 400;
}

.pdpop-hr {
    margin: 0;
}

.shared-post {
    max-width: 800px;
    margin: auto;
    padding: 30px 0px;
}

.pm-content-container {
    position: relative;
    width: 54%;
}

.post-pop-footer-container-desktop {
    position: absolute;
    bottom: 10px;
    width: 100%;
    /* border-top: 1px solid #ddd; */
    padding: 10px;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-prev {
    position: absolute;
    top: 50%;
    left: -20px;
    font-size: 40px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.nav-next {
    position: absolute;
    top: 50%;
    right: -20px;
    font-size: 40px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    cursor: pointer;
}

.actionsdp-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-cont {
    padding: 10px 5px 0 5px;
}

.actions-icons {
    font-size: 25px;
}

.actionsdp-list a.btn.btn-default {
    border: 1px solid #dddd;
    margin-right: 10px;
    height: 25px;
}

.follow-btn {
    margin-left: 20px;
}

.share-icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    /* padding: 10px; */
    align-items: center;
    height: 54px;
}

.detial-search {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-like {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
}

.like-content {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    background-color: #edd3ff73;
    padding: 10px 16px;
    border-radius: 6px;
    align-items: center;
}

.like-name {
    text-decoration: none;
    color: #000;
    font-weight: 600;
}

.profile-picture {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.post-shadow {
    padding-bottom: 3px;
    border-bottom: 1px solid #cacaca;
}

.profile-follow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-right: 10px; */
}

.open-comments {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 12px;
}

.modal-body .hide-mobile {
    overflow-wrap: anywhere;
    padding: 3px 5px;
}

.shared-post .open-comments-desktop {
    max-height: 280px;
}

@media screen and (max-width: 767.98px) {

    .modal-content .post-modal-view,
    .shared-post .post-modal-view {
        flex-direction: column-reverse;
        max-height: max-content;
    }

    .pm-content-container {
        width: 100%;
    }

    .profile-pic-home {
        width: 40px;
        height: 40px;
        margin-right: 11px;
    }

    .profile-titles a {
        font-family: Poppins;
        font-size: 15px;
        line-height: 22.5px;
    }

    .post-footer {
        margin-bottom: 15px;
    }

    .post-caption {
        margin-bottom: 5px !important;
        font-size: 13px !important;
        line-height: 19.5px !important;
    }

    .post-caption strong {
        font-size: 15px;
        line-height: 22.5px;
    }

    .post-comments {
        font-size: 13px !important;
        margin-bottom: 5px !important;
    }

    .postheads {
        margin-bottom: 15px;
    }

    .hide-mobile {
        display: none;
    }

    .post-shadow {
        border-bottom: none;
    }

    /* .navigation-buttons{
        display: none;
    } */

    .modal.fade .modal-dialog {
        margin: auto;
        border-radius: 0px;
    }

    .post-shadow {
        padding: 0;
        box-shadow: 0px 0px 0px 0px #fff;
    }

    .post-modal-view {
        flex-direction: column-reverse;
    }

    img.post-modal-img {
        max-height: none;
    }

    p.posttitle {
        margin: 0;
        flex: 5;
        font-weight: 700;
        font-size: 15px;
    }

    /* .search-container  .profile-pic-div .profile-pic {
       
    } */

    .model-header-popup {
        display: flex;
        align-items: center;
    }

    .model-header-wid {
        width: 100%;
    }

    .model-popup-back {
        flex: 4;
    }

    .pm-img-container {
        text-align: center;
        flex-direction: column;
    }

    /* .mobile-pop-foot .shortlist {
        flex: 1;
    } */

    .nav-next {
        right: 0;
    }

    .nav-prev {
        left: 0;
    }

    .modal.fade .modal-dialog {
        margin: auto;
        border-radius: 0px;
        /* padding: 31px; */
    }

    .search-screen-container .products-container {
        padding: 0px;
    }

    .content-area {
        margin-top: 50px !important;
        width: 100%;
    }

    .share-icon {
        height: 67px;
    }

    .view-btn,
    .message-btn {
        font-size: 15px;
    }

    .search-container {
        padding: 0px;
    }

    .post-footer-container {
        margin: 0px 10px 6px;
    }

    .post-new-comment {
        position: relative;
        right: 0;
        bottom: 0;
        width: 100%;
    }

    .post-footer-container {
        text-align: start;
    }

    .filter-btn {
        margin: 0;
        padding: 2px 10px;
    }

    .filter-btn img {
        display: none;
    }
}

@media screen and (max-width: 991.98px) {
    /* .search-container  .profile-pic-div .profile-pic {
        min-width: 265px;
        width: 100%;
        min-height: 300px;
        height: 300px;
    } */

    .shared-post .post-modal-view,
    .modal-content .post-modal-view {
        flex-direction: column-reverse;
        max-height: max-content;
    }

    .post-new-comment {
        position: relative;
        right: 0;
        bottom: 0;
        width: 100%;
    }

    .post-footer-container {
        margin: 0px 10px 6px;
    }

    .post-footer-container p {
        text-align: start;
        margin-bottom: 5px;
    }

    .pm-content-container {
        width: 100%;
    }

    .post-footer-container-desktop {
        padding: 0 10px;
    }
}

.homescreen .post-image {
    background: #212529;
    border: 1px solid #6A0DAD;
}

.post-footer-container-desktop {
    padding: 0 10px;
    /* position: absolute; */
    width: 100%;
    bottom: 0px;
}

.custom-dropdown-menu {
    display: none;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}


.dropdown.show .custom-dropdown-menu {
    display: block;
    opacity: 1;
    transform: translateY(0);
}


.custom-dropdown-menu {
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.like-count {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-family: "poppins";
    color: #0E1027;
}

p strong {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #0E1027;
}

.post-caption {
    font-size: 13.5px;
    line-height: 22.5px;
    font-weight: 400;
    color: #6c6c74;
    margin-bottom: 10px;
}

.post-comments {
    margin-bottom: 10px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #6c6c74;
}

img.user-profile {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.user-detail .user-suugestionpage2 {
    font-size: 10px;
    margin: 0 !important;

}

.user-detail .user-suugestionpage {
    font-size: 12.5px;
    font-weight: 500;
    margin: 0 !important;

}

.adpost-container .suggested {
    font-size: 15px;
    font-weight: 500;
    color: #555555;
}

.adpost-container .suggested2 {
    font-size: 15px;
    font-weight: 500;
    color: #212529;
}

.detail-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-content {
    display: flex;
    align-items: center;
    column-gap: 8px;
}